.NavBarWrapper {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #0086a5;
    min-height: 1px;
    position: relative;
}


@media only screen and (max-width: 800px) {
    .NavBarWrapper {
        padding-right: 30px;
        text-align: right;
    }
}

