@import url('https://fonts.googleapis.com/css2?family=Uchen&display=swap');

.viewPostModalWrapper {
    background-color: #9de2ff;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    right: 0;
    display: none;
    height: 100%;
    left: 0;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    z-index: 9999;
   
}

.postViewImg {
    width: 100%;
    height: 350px;
    background-position: top center !important;
    background-size: contain !important;
    margin: 0 auto;
    background-repeat: no-repeat !important;
    border-radius: 5px;
    
}
.postViewHeader {
    font-family: 'Uchen', serif;
    font-style: italic;
    margin: 30px 0 ;
    color: #0c5390;
    font-size: 1.4vw;
    letter-spacing: 1px;
}
.postViewBody{
    font-family: 'Uchen', serif;
    flex: 1;
    text-align: left;
    padding: 40px;

}

.postViewBody::before {
    content: "***";
    display: block;
    text-align: center;
    padding: 10px;
}

.viewPostModalContent {
    background-color: #fff;
    margin: 0 20%;
    position: relative;
    padding: 5% 4% 3% 4%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
}

.authorSetion {
    display: flex;
    flex-direction: row;
    display: flex;
    border-top: 1px solid rgb(168, 52, 52);
    padding: 15px 20px;
    
}

.postViewAuthor,
.postViewDate{
   flex: 1;
   text-align: left;
   font-style: italic;
}

.postViewAuthor {
    color: #0c5390;
    text-align: right;
}


 

.postViewCloseBtn {
    position: absolute;
    cursor: pointer;
    top: 30px;
    right: 40px;
    padding: 7px 12px;
    font-size: 0.9vw;
    border-radius: 10px;
    background-color: rgb(254, 132, 136);
    border: 2px solid rgb(138, 0, 0);
    font-weight: bold;
    color: #fff;
}

@media only screen and (max-width: 800px) {
    .viewPostModalContent {
        margin: 0 2%;
    }
}